// src/components/About.js
import React from 'react';
import './About.css';
import { FaBrain, FaChartLine, FaDatabase } from 'react-icons/fa';

const About = () => {
  return (
    <section id="about" className="about-section">
      <h2>About Me</h2>
      <p>
        I am a seasoned consultant specializing in Machine Learning, Artificial Intelligence, and Data Science. With years of experience, I help businesses leverage data to drive growth and innovation.
      </p>
      <div className="icons">
        <div className="icon">
          <FaBrain size={50} />
          <h3>Machine Learning</h3>
        </div>
        <div className="icon">
          <FaChartLine size={50} />
          <h3>Data Analysis</h3>
        </div>
        <div className="icon">
          <FaDatabase size={50} />
          <h3>AI Solutions</h3>
        </div>
      </div>
    </section>
  );
}

export default About;