// src/components/Footer.js
import React from 'react';
import './Footer.css';
import { FaLinkedin, FaTwitter, FaGithub } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <p>© {new Date().getFullYear()} Ankit M. All rights reserved.</p>
      <div className="social-icons">
        <a href="https://linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer"><FaLinkedin size={30} /></a>
        <a href="https://twitter.com/yourprofile" target="_blank" rel="noopener noreferrer"><FaTwitter size={30} /></a>
        <a href="https://github.com/yourprofile" target="_blank" rel="noopener noreferrer"><FaGithub size={30} /></a>
      </div>
    </footer>
  );
}

export default Footer;