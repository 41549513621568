// src/components/Services.js
import React from 'react';
import './Services.css';
import { FaRobot, FaChartBar, FaServer } from 'react-icons/fa';

const Services = () => {
  return (
    <section id="services" className="services-section">
      <h2>My Services</h2>
      <div className="services-container">
        <div className="service">
          <FaRobot size={40} />
          <h3>AI Development</h3>
          <p>Building intelligent systems that learn and adapt to your business needs.</p>
        </div>
        <div className="service">
          <FaChartBar size={40} />
          <h3>Data Analysis</h3>
          <p>Transforming raw data into actionable insights to drive your strategy.</p>
        </div>
        <div className="service">
          <FaServer size={40} />
          <h3>Data Engineering</h3>
          <p>Designing robust data pipelines and architectures for seamless data flow.</p>
        </div>
      </div>
    </section>
  );
}

export default Services;