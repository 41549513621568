// src/components/Header/Header.js
import React, { useState, useEffect } from 'react';
import './Header.css';
import { Link } from 'react-scroll';
import { FaBars, FaTimes } from 'react-icons/fa'; // Import hamburger and close icons

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [navbarBackground, setNavbarBackground] = useState(false);

  // Toggle Drawer
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Close Drawer
  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  // Handle Scroll to Toggle Navbar Background
  useEffect(() => {
    const handleScroll = () => {
      const headerHeight = document.querySelector('.header').offsetHeight;
      const scrollTop = window.scrollY;

      if (scrollTop > headerHeight - 70) { // Adjust threshold as needed
        setNavbarBackground(true);
      } else {
        setNavbarBackground(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup Listener on Unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className="header">
      <nav className={`navbar ${navbarBackground ? 'navbar-solid' : 'navbar-transparent'}`}>
        <h1 className="logo">Ankit M.</h1>
        <ul className="nav-links">
          <li>
            <Link
              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              About
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="services"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Contact
            </Link>
          </li>
        </ul>
        <div
          className="hamburger"
          onClick={toggleDrawer}
          aria-label={drawerOpen ? "Close navigation menu" : "Open navigation menu"}
          aria-expanded={drawerOpen}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') toggleDrawer();
          }}
        >
          {drawerOpen ? <FaTimes size={30} /> : <FaBars size={30} />}
        </div>
      </nav>
      <div className={`drawer ${drawerOpen ? 'open' : 'closed'}`}>
        <ul>
          <li onClick={closeDrawer}><a href="#about">About</a></li>
          <li onClick={closeDrawer}><a href="#services">Services</a></li>
          <li onClick={closeDrawer}><a href="#contact">Contact</a></li>
        </ul>
      </div>
      <div className="header-content">
        <h2>Machine Learning & AI Consulting</h2>
        <p>Empowering your business with data-driven solutions.</p>
        <a href="#contact" className="btn">Get Started</a>
      </div>
    </header>
  );
};

export default Header;